<template>

  <div class="row" ref="projectForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
               <div class="row">
                 <div class="col-md-6">
                   <ValidationProvider
                           vid="name"
                           rules="required"
                           name="The Name"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="name"
                               name="name"
                               @keyup="generateSomeFields"
                               fou
                               v-model="formData.name">
                     </fg-input>
                   </ValidationProvider>
                 </div>
                 <div class="col-md-6">
                   <ValidationProvider
                           vid="url"
                           rules="required"
                           name="The URL"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Project URL"
                               name="url"
                               fou
                               v-model="formData.url">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
                <fg-input type="text"
                          label="Top Title"
                          name="top_title"
                          fou
                          v-model="formData.top_title">
                </fg-input>
                <fg-input type="text"
                            label="Title"
                            name="title"
                            fou
                            v-model="formData.title">
                  </fg-input>
              </div>
              <div class="row col-md-12">
                <div class="col-md-6">
                  <fg-select
                    name="sponsors"
                    size="large"
                    filterable
                    clearable
                    multiple
                    placeholder="Select Sponsors"
                    :input-classes="'select-default'"
                    :label="'Sponsors'"
                    :list="sponsorsList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.sponsors">
                  </fg-select>
                </div>
                <div class="col-md-6">
                  <fg-select
                    name="classes"
                    size="large"
                    filterable
                    clearable
                    multiple
                    placeholder="Select Classes"
                    :input-classes="'select-default'"
                    :label="'classes'"
                    :list="classesList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.classes">
                  </fg-select>
                </div>
                <div class="col-md-12">
                  <fg-select
                    name="performers"
                    size="large"
                    filterable
                    clearable
                    multiple
                    placeholder="Select performers"
                    :input-classes="'select-default'"
                    :label="'Performers'"
                    :list="sponsorsList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.performers">
                  </fg-select>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right" v-if="true">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Cover image</label>
                  <el-tooltip placement="right" v-if="true">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.cover_image"
                  >
                  </prime-uploader>
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content 1</label>
                  <editor
                    v-model="formData.content1"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <label>Content File</label>
                <ValidationProvider
                  vid="file_type"
                  rules=""
                  name="The file type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="file_type"
                    size="large"
                    filterable
                    clearable
                    placeholder="File Type"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'File type'"
                    :list="fileTypes"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    v-model="file_type">
                  </fg-select>
                </ValidationProvider>
                <div v-if="file_type==='IMAGE'" class="form-group">
                  <label>Content image</label>
                  <el-tooltip placement="right" v-if="true">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.content_image"
                  >
                  </prime-uploader>
                </div>
                <div v-if="file_type==='VIDEO'">
                  <ValidationProvider
                    vid="video_type"
                    rules=""
                    name="The Video Type"
                    v-slot="{ passed, failed,errors }">
                    <fg-select
                      name="video_type"
                      size="large"
                      filterable
                      clearable
                      placeholder="Video Type"
                      :error="failed ? errors[0]: null"
                      :input-classes="'select-default'"
                      :label="'Video Type'"
                      :list="VideoTypes"
                      :listItemLabel="'label'"
                      :listItemValue="'value'"
                      v-model="formData.video_type">
                    </fg-select>
                  </ValidationProvider>
                  <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                    <label>Content uploaded video</label>
                    <el-tooltip placement="right" v-if="true">
                      <div slot="content"></div>
                      <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                    </el-tooltip>
                    <prime-video-uploader
                      :preview-width="'200px'"
                      :preview-height="'200px'"
                      :maxFileSize="50000000"
                      v-model="formData.uploaded_video"
                    >
                    </prime-video-uploader>
                  </div>
                  <div class="form-group" v-if="formData.video_type == 'EMBEDDED'">
                    <el-tooltip placement="right" v-if="true">
                      <div slot="content"></div>
                      <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                    </el-tooltip>
                    <ValidationProvider
                      vid="embedded_video"
                      rules=""
                      name="The Embedded Video"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Content embedded video"
                                name="embedded_video"
                                fou
                                v-model="formData.embedded_video">
                      </fg-input>
                    </ValidationProvider>
                    <div v-html="formData.embedded_video">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content 2</label>
                  <editor
                    v-model="formData.content2"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>
              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Gallery</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="99"
                    :maxFileSize="2000000"
                    :isEditMode="editMode"
                    v-model="formData.gallery"
                  >
                  </prime-uploader>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="gallery_title"
                      rules=""
                      name="The gallery title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Gallery title"
                                name="url"
                                fou
                                v-model="formData.gallery_title">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="gallery_subtitle"
                      rules=""
                      name="The gallery subtitle"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Gallery subtitle"
                                name="gallery_subtitle"
                                fou
                                v-model="formData.gallery_subtitle">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <label>Banner image</label>
                  <el-tooltip placement="right" v-if="true">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.banner_image"
                  >
                  </prime-uploader>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="banner_text"
                      rules=""
                      name="The banner text"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Banner text"
                                name="url"
                                fou
                                v-model="formData.banner_text">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="card-label">Date</label>
                    <fg-input v-model="formData.date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Date"
                    >
                    </fg-input>
                  </div>
                </div>
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/projects/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        url: "",
        title: "",
        image: "",
        content1: "",
        content2: "",
        content_image: "",
        video_type: '',
        uploaded_video: "",
        embedded_video: "",
        gallery: [],
        gallery_title: '',
        gallery_subtitle: '',
        banner_image: '',
        banner_text: '',
        date: null,
        is_active: true,
        sponsors: [],
        classes: [],
        performers: [],
        top_title: '',
        cover_image: '',

      },
      VideoTypes: [],
      sponsorsList: [],
      classesList: [],
      fileTypes: [
        {
          label: 'IMAGE',
          value: 'IMAGE'
        },
        {
          label: 'VIDEO',
          value: 'VIDEO'
        }
      ],
      file_type: 'IMAGE'

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.projectForm
    });

    this.axios.post("projects/builder").then((response) => {
      this.VideoTypes = response.data.videoTypes;
      this.sponsorsList = response.data.sponsors;
      this.classesList = response.data.classes;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Project";
        this.getProject();
      } else {
        this.editMode = false;
        this.formTitle = "Add Project";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getProject() {
      let data = {
        'id' : this.id,
      };
      this.axios.post("projects/get",data).then((response) => {
        this.formData = response.data;
        this.formData.sponsors = response.data.sponsors.map(sponsor => sponsor.id);
        this.formData.classes = response.data.classes.map(cclass => cclass.id);
        this.formData.performers = response.data.performers.map(performer => performer.id);
        if (this.formData.content_image){
          this.file_type = 'IMAGE';
        }else if (this.formData.uploaded_video || this.formData.embedded_video){
          this.file_type = 'VIDEO';
        }
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Project Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    generateSomeFields() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "_").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "_");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }

      this.formData.url = newUrl;
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.file_type === "IMAGE"){
        this.formData.uploaded_video = '';
        this.formData.embedded_video = '';
        this.formData.video_type = '';
      }else{
        this.formData.content_image = '';
      }
      if (this.formData.video_type == "UPLOADED"){
          this.formData.embedded_video = '';
      }else{
          this.formData.uploaded_video = '';
      }
      if (this.formData.embedded_video == '' && this.formData.uploaded_video == ''){
        this.formData.video_type = '';
      }
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("projects/update/" + this.id, this.formData);
        successMessage = "Project Updated Successfully";
      } else {
        request = this.axios.post("projects/create", this.formData);
        successMessage = "Project Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/projects/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    vueDate(){
        this.formData.date = (this.formData.date)? this.formData.date.replace(' ', 'T'):this.formData.date;
    },
    laravelDate(){
        this.formData.date = (this.formData.date)? this.formData.date.replace('T', ' '):this.formData.date;
    },

  }
}
</script>
